<template>
  <div :class="requiredClass" class="trans-item">
    <div class="btn-burger centered pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <div class="inp-group inp-group__xl">
            <input
              :value="value.title"
              title="Text"
              type="text"
              @input="handleInput($event, 'title')"
            />
          </div>
        </div>
      </div>
      <div class="main__content-item">
        <div class="main__content-item_l">
          <span>Image:</span>
        </div>
        <div class="main__content-item_r">
          <media-uploader
            key="image_and_text"
            :auto-processing="false"
            :media="value.file"
            :multiple="false"
            :processing-u-r-l="dropzoneSettings.url"
            :request-headers="dropzoneSettings.headers"
            field-name="file"
            footer-text="Drag&drop or <u>click here</u> to upload images (jpg, png 320x200 up to 5MB)"
            type="image"
            @onRemove="removeFile"
            @onUploadCompleted="setImage"
          />
        </div>
      </div>
    </div>
    <button class="field__remove" @click="removeSection"></button>
  </div>
</template>

<script>
import { API_ENDPOINT } from "@/utils/api";
import MediaUploader from "@/components/inputs/MediaUploader.vue";

export default {
  name: "ImageAndText",
  props: ["value"],
  components: { MediaUploader },
  computed: {
    requiredClass() {
      return {
        gray: this.value.required === true,
      };
    },
    dropzoneSettings() {
      return {
        url: API_ENDPOINT + "v2/builds/media",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      };
    },
  },
  methods: {
    handleInput(event, key) {
      let emitted = { ...this.value };
      emitted[key] = event.target.value;
      this.$emit("input", emitted);
    },
    removeSection() {
      this.$emit("onRemove");
    },
    setImage(response) {
      let emitted = { ...this.value };
      emitted.file = response[0];
      this.$emit("input", emitted);
    },
    removeFile() {
      this.$emit("input", null);
    }
  },
};
</script>

<style scoped></style>
